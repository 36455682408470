<template>
  <div class="error d-flex flex-column justify-content-between">
    <div class="text-center text-white error-text pt-5 mt-5">
      <h1 class="display-1 font-weight-bold pt-3 mt-3">404</h1>
      <h3>We can't seem to find that page - time to book an eye test</h3>
    </div>
    <router-link class="btn btn-danger" to="/"
      ><h3 class="text-white">HOME</h3>
    </router-link>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "404",
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
